<template>
  <div class="container">
      <Title msg="Favoritos"/>
      <favorite />

  </div>
</template>
<script>
import favorite from '@/components/student/favorite/index.vue'
import Title from '@/views/student/constants/title.vue'

export default {
  name: 'dataStudent',
  components: {
    favorite,
    Title
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scope>

</style>