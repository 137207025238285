<template>
  <div>
    <div class="barra"></div>
    <userSession />
    <div class="row justify-content-md-center">
      <div class="col">
        <h1 class="text-center mt-4 text-title">
          <router-link
            :to="{ name: 'Courses' }"
            style="color: #007bff; padding-right: 10px"
          >
            <i class="fas fa-angle-left"></i>
          </router-link>
          {{ msg }}
        </h1>
      </div>
    </div>
  </div>
</template>
<script>
import userSession from "@/components/student/notifications/userSession/index.vue";
export default {
  name: "Title",
  props: {
    msg: String,
  },
  components: {
    userSession,
  },
};
</script>
<style scoped>
.text-title {
  font-family: "Ubuntu", Helvetica, Arial;
  font-size: 50px;
}
h1 {
  font-family: "Ubuntu", sans-serif, Helvetica;
}
.barra {
  height: 100px;
}
</style>
