<template>
  <div>
        <!---  End Preload -->
        <div v-if="loadingFavorites">
            <loadingQuiz />
        </div>

        <div v-else>
            <div class="row justify-content-md-center" v-if="favorites">
                <div class="col-12 col-sm-12 col-md-12 col-lg-10"
                v-for="(favorite, index) in favorites" 
                :key="favorite.question_id">
                    <router-link :to="{ name: 'favoriteShow', 
                                        params: { url: favorite.question_id } 
                                        }" class="favorite_text">
                        <div class="row dcourse color-text">
                            <div class="col">
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <p class="pTeamName">
                                        {{index + 1}} -
                                        {{favorite.question.course.name}} -
                                        {{favorite.question.team.name}} -
                                        {{favorite.question.year.name}}
                                        </p>                                                                
                                    </div>
                                    <div class="col-2">
                                        <p class="pTeamName text-right">
                                            {{favorite.question.block.name}}
                                        </p>                                                                
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div v-html="favorite.question.body" class="pTeamDetall"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>                
                </div>
            </div>
            <div v-else>
                <p>Aun no tiene favoritos</p>
            </div>
            <div class="row justify-content-center" v-if="loadingNextPageFavorites">
                <loadingSectionNextPageFavorites/>
            </div>
            <div class="row justify-content-center py-4" v-if="this.page < this.lastPage">
                <div class="col text-center btn_action" v-on:click="loadingNexPage">
                    <p>Cargar más</p>
                </div>
                <div class="col-">
                    <div v-on:click="scrollToTop" class="btnUp btn_action">
                        <i class="fas fa-chevron-circle-up"></i>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center py-4" v-else>
                <div class="col text-center">
                    <p>Fin de favoritos</p>
                </div>
                <div class="col-">
                    <div v-on:click="scrollToTop" class="btnUp btn_action">
                        <i class="fas fa-chevron-circle-up"></i>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/theme.vue";
import loadingSectionNextPageFavorites from "@/views/student/constants/loading-section/next-page.vue";

export default {
    name: 'favoritesIndex',
    components: {
        loadingQuiz,
        loadingSectionNextPageFavorites
    },
    data(){
        return{
            favorites:[],
            loadingFavorites:false,
            loadingNextPageFavorites:false,
            page:1,
            lastPage:1
        }
    },
    created(){
        this.loadingFavorites = true
        this.getFavorites();
        this.loadingFavorites = true
    },
    methods:{
        getFavorites(){
            const url = this.appDomainApi1 + "favorites/data?page=" + this.page;
            axios.get(url)
                .then((resp) => {
                    if(resp.data.code == 200){
                        if( this.page == 1){
                            this.favorites = resp.data.allFavorites.data;
                            this.lastPage = resp.data.allFavorites.last_page
                            this.loadingFavorites = false
                        } else {
                            this.loadingNextPageFavorites = true
                            this.favorites.push(...resp.data.allFavorites.data);
                            this.lastPage = resp.data.allFavorites.last_page
                            this.loadingNextPageFavorites = false
                        }
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        },
        loadingNexPage(){
            if( this.page < this.lastPage ){
                const nextPage = this.page + 1;
                this.page = nextPage;
                this.loadingNextPageFavorites = true
                this.getFavorites();
                console.log("nextPage")
                console.log(nextPage)
            } else {
                console.log("End")
            }
        },
        openFavorite(id){
            window.location.href = "/favoritos/" + id;
        },
        scrollToTop() {
            var rootElement = document.documentElement
            rootElement.scrollTo({
                top: 0,
                behavior: "smooth"
        })
        },
    },
}
</script>

<style>
.color-text{
    color:#2c3e50; 
}
.btnUp{
    color:#007bff; 
    padding-right: 10px;
    font-size: 25px;
}
.btn_action{
    cursor: pointer;
}
.favorite_text:hover {
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
.dcourse:hover {
    transition-duration: 100ms;
    box-shadow: 5px 5px 15px -3px #76767687;
}
.dcourse {
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    /*background: #E8F0FF;*/
    margin-top: 10px;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-bottom: 10px;
    background: white;

}
.favoriteLoading{
    background: #333333; 
    padding:15px; 
    height: 6.8em;
  }
.dc1 {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    /*background: green;*/
	margin: 5px;
}
</style>
